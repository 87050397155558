import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { UserRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { userService } from '../service/userService';
import { usersForEnrollCacheKey } from './cacheKey';

export const useUsersForEnrollQuery = (organizationId: UUID, organizationContractId: UUID) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [usersForEnrollCacheKey, organizationId, organizationContractId],
        queryFn: () => userService.getUsersForEnroll(organizationId, organizationContractId),
    });

    const users: UserRefDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.users;
    }, [data]);

    return {
        users,
        usersIsLoading: isLoading,
        isError,
    };
};
