import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { DropdownCheckboxListSearch, FormContainer } from '@hofy/ui';

import { UseEnrollUserForm } from '../../../../store/organizationContracts/useEnrollUsersForm';
import { useUserIdFilterDefinition } from '../../../../store/organizationContracts/useUserIdFilterDefinition';
import { DropdownCustomTrigger } from '../components/DropdownCustomTrigger';
import { SelectedItemsChipsBox } from '../components/SelectedItemsChipsBox';

interface EnrollUsersFormProps {
    enrollUsersForm: UseEnrollUserForm;
    organizationId: UUID;
    organizationContractId: UUID;
}

export const EnrollUsersForm: FC<EnrollUsersFormProps> = ({
    enrollUsersForm,
    organizationId,
    organizationContractId,
}) => {
    const { form } = enrollUsersForm;
    const { toLabel, allValues } = useUserIdFilterDefinition({ organizationId, organizationContractId });

    const removeSelected = (id: UUID) => {
        form.fields.userIds.setValue(form.fields.userIds.value.filter(item => item !== id));
    };

    return (
        <FormContainer marginVertical={40}>
            <SelectedItemsChipsBox<UUID>
                title='Users to enroll'
                items={form.fields.userIds.value}
                toLabel={toLabel}
                remove={removeSelected}
            />
            <DropdownCheckboxListSearch
                onChange={v => {
                    form.fields.userIds.setValue(v);
                }}
                options={allValues}
                values={form.fields.userIds.value}
                toText={toLabel}
                searchPlaceholder='User name or email'
                toKey={option => option.toString()}
                trigger={DropdownCustomTrigger}
            />
        </FormContainer>
    );
};
