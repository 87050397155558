import React, { FC } from 'react';

import { BYODOrderDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import {
    Box,
    CountryFlag,
    CountryLabel,
    FormGridRow,
    FormSection,
    Labeled,
    LabeledText,
    Paragraph3,
} from '@hofy/ui';

import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { useDownloadBYODOrderDocument } from '../../../../store/byodOrders/useDownloadBYODOrderDocument';
import { LabeledOrganizationLink } from '../../../organizationsPage/OrganizationDetailsLink';
import { getTrackingLink } from '../../../shipmentSlideout/components/CourierDetails';
import { DocumentFilesTable } from './DocumentFiles';

interface BYODOrderDetailsContentProps {
    byodOrder: BYODOrderDto;
}

export const BYODOrderDetailsContent: FC<BYODOrderDetailsContentProps> = ({ byodOrder }) => {
    const { isDownloading, download, isDownloadError } = useDownloadBYODOrderDocument();

    return (
        <Box marginVertical={30} gap={30} column>
            <FormSection label='BYOD order details' marginRight={40} flex={1} overflow='hidden'>
                <FormGridRow columns={2}>
                    <LabeledText label='Public Id' content={byodOrder.publicId} />
                    <LabeledText label='Id' content={byodOrder.id} />
                </FormGridRow>
                <FormGridRow columns={2}>
                    <LabeledOrganizationLink organization={byodOrder.organization} />
                    <LabeledText label='Order Reference' content={byodOrder.orderReference} />
                </FormGridRow>
            </FormSection>

            <Box row alignItems='flex-start'>
                <FormSection label='Shipment details' marginRight={40} flex={1} overflow='hidden'>
                    <FormGridRow columns={2}>
                        <Labeled
                            label='Origin country'
                            content={<CountryLabel country={byodOrder.fromCountry} />}
                        />
                        <Labeled
                            label='To warehouse'
                            content={
                                <Box row gap={8}>
                                    <CountryFlag country={byodOrder.toWarehouse.address?.country} />
                                    <Paragraph3>{byodOrder.toWarehouse.name}</Paragraph3>
                                </Box>
                            }
                        />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText label='Tracking Reference' content={byodOrder.courierReference} />
                        <Labeled label='Tracking link' content={getTrackingLink(byodOrder.trackingLink)} />
                    </FormGridRow>
                    <LabeledText label='Estimated on' content={formatDate(byodOrder.estimatedReceiveOn)} />
                </FormSection>
            </Box>

            <FormSection label='Documents'>
                <Box overflow='auto'>
                    <DocumentFilesTable
                        files={byodOrder.documentFiles}
                        download={download}
                        isDownloading={isDownloading}
                        isDownloadError={isDownloadError}
                    />
                </Box>
            </FormSection>
            <AuditSection model={byodOrder} />
        </Box>
    );
};
