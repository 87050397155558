import React, { FC } from 'react';

import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { AsyncButton } from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useEnrollUsers } from '../../../../store/organizationContracts/useEnrollUsers';
import { EnrollUsersForm } from './EnrollUsersForm';

interface EnrollUsersOverlayProps {
    onClose(): void;
    organizationId: UUID;
    organizationContractId: UUID;
}

export const EnrollUsersSlideout: FC<EnrollUsersOverlayProps> = ({
    onClose,
    organizationId,
    organizationContractId,
}) => {
    const title = 'Enroll users';
    const form = useEnrollUsers(onClose, organizationId, organizationContractId);

    return (
        <Slideout width={600} onClose={onClose} slideoutId='enroll-usesr'>
            <SlideoutHeader title={title} />
            <SlideoutContent>
                <EnrollUsersForm
                    enrollUsersForm={form}
                    organizationId={organizationId}
                    organizationContractId={organizationContractId}
                />
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} label='Cancel' />
                <AsyncButton isLoading={form.isLoading} label='Save' onClick={form.form.submit} />
            </SlideoutFooter>
        </Slideout>
    );
};
