import React, { FC } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { pathUuid, UUID } from '@hofy/global';
import { UUIDRoute } from '@hofy/router';

import { BYODOrdersPage } from './BYODOrdersPage';
import { NewBYODOrderSlideout } from './createSlideout/NewBYODOrderSlideout';
import { BYODOrderDetailsSlideout } from './detailsSlideout/BYODOrderDetailsSlideout';
import { ReceiveBYODOrderSlideout } from './receiveSlideout/ReceiveBYODOrderSlideout';

export const BYODOrdersRouter: FC = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const handleSlideoutClose = () => history.push(`${path}${history.location.search}`);

    const handleNewBYODOrder = () => {
        history.push(`${path}/new${history.location.search}`);
    };

    const handleBYODOrderDetails = (id: UUID) => {
        history.push(`${path}/${id}/details${history.location.search}`);
    };

    const handleReceiveBYODOrder = (id: UUID) => {
        history.push(`${path}/${id}/receive${history.location.search}`);
    };

    return (
        <>
            <BYODOrdersPage
                onNewBYODOrder={handleNewBYODOrder}
                onReceiveBYODOrder={handleReceiveBYODOrder}
                onBYODOrderDetails={handleBYODOrderDetails}
            />
            <Switch>
                <ReceiveBYODOrderRoute exact path={`${path}/:id(${pathUuid})/receive`}>
                    {({ id }) => <ReceiveBYODOrderSlideout onClose={handleSlideoutClose} id={id} />}
                </ReceiveBYODOrderRoute>
                <BYODOrderDetailsRoute exact path={`${path}/:id(${pathUuid})/details`}>
                    {({ id }) => <BYODOrderDetailsSlideout id={id} onClose={handleSlideoutClose} />}
                </BYODOrderDetailsRoute>
                <Route exact path={`${path}/new`}>
                    <NewBYODOrderSlideout onClose={handleSlideoutClose} />
                </Route>
            </Switch>
        </>
    );
};

const ReceiveBYODOrderRoute = UUIDRoute('id');
const BYODOrderDetailsRoute = UUIDRoute('id');
