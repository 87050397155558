import React, { FC, useEffect } from 'react';

import {
    customerOwnedAllStoreAndReuseLocations,
    CustomerOwnedStoreAndReuseLocation,
    customerOwnedStoreAndReuseLocationLabels,
} from '@hofy/api-shared';
import { Price } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, LabeledNumberInput, LabeledPriceInput, LabeledSelect } from '@hofy/ui';

interface CustomerOwnedStoreAndReuseLocationFieldProps {
    collectToHub: boolean;
    collectToLocalPartner: boolean;

    location: CustomerOwnedStoreAndReuseLocation | null;
    activationFee: Price;
    monthlyFee: Price;
    gracePeriodInMonths: number;

    onCustomerOwnedStoreAndReuseLocationChange(location: CustomerOwnedStoreAndReuseLocation): void;
    onActivationFeeChange(activationFee: Price): void;
    onStorageMonthlyFeeChange(monthlyFee: Price): void;
    onGracePeriodInMonthsChange(gracePeriodInMonthsChange: number): void;

    errorMessage?: string;
}

export const CustomerOwnedStoreAndReuseLocationField: FC<CustomerOwnedStoreAndReuseLocationFieldProps> = ({
    collectToHub,
    collectToLocalPartner,
    location,
    activationFee,
    monthlyFee,
    gracePeriodInMonths,
    errorMessage,
    onCustomerOwnedStoreAndReuseLocationChange,
    onActivationFeeChange,
    onStorageMonthlyFeeChange,
    onGracePeriodInMonthsChange,
}) => {
    useEffect(() => {
        if (collectToLocalPartner === collectToHub) {
            // Present choice
            return;
        }

        if (collectToHub) {
            onCustomerOwnedStoreAndReuseLocationChange(CustomerOwnedStoreAndReuseLocation.HofyHub);
        } else if (collectToLocalPartner) {
            onCustomerOwnedStoreAndReuseLocationChange(CustomerOwnedStoreAndReuseLocation.LocalHub);
        }
    }, [collectToLocalPartner, collectToHub]);

    return (
        <Box rounded marginVertical={10} padding={10} gap={10} column bg={Color.BackgroundSubtleNeutral}>
            <LabeledSelect
                label='Collection location'
                toText={value => customerOwnedStoreAndReuseLocationLabels[value]}
                value={location}
                onChange={onCustomerOwnedStoreAndReuseLocationChange}
                isRequired
                errorMessage={errorMessage}
                options={customerOwnedAllStoreAndReuseLocations.filter(
                    opt =>
                        (opt === CustomerOwnedStoreAndReuseLocation.HofyHub && collectToHub) ||
                        (opt === CustomerOwnedStoreAndReuseLocation.LocalHub && collectToLocalPartner),
                )}
            />
            <LabeledPriceInput
                label='Store and reuse fee'
                value={activationFee}
                onChange={activationFee => onActivationFeeChange(activationFee)}
            />
            <LabeledPriceInput
                label='Monthly storage fee'
                value={monthlyFee}
                onChange={monthlyFee => onStorageMonthlyFeeChange(monthlyFee)}
            />
            <LabeledNumberInput
                value={gracePeriodInMonths}
                onChange={p => onGracePeriodInMonthsChange(p)}
                label={'Grace period in months'}
            />
        </Box>
    );
};
