import {
    AddressDto,
    AssignmentDto,
    AuditableModelDto,
    ContractUnionDto,
    OrganizationRefDto,
    ProductDto,
    UserStatus,
    VariantDto,
    ZendeskTicketStatusDto,
} from '@hofy/api-shared';
import { DateString } from '@hofy/global';

export enum IssueType {
    Software = 'software',
    Hardware = 'hardware',
    Unknown = 'unknown',
}

export interface IssueDetailsDto extends AuditableModelDto {
    publicId: string;
    description: string;
    resolvedOn: DateString | null;
    assignment: AssignmentDto;
    contract: ContractUnionDto | null;
    product: ProductDto;
    variant: VariantDto;
    user: IssueUserDto;
    organization: OrganizationRefDto;
    zendesk: ZendeskTicketStatusDto;
    isAbleToWork: boolean;
    issueType: IssueType;
    softwareDescription: string;
}

export interface IssueUserDto {
    address: AddressDto;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    status: UserStatus;
}
