import { SeatAssignmentStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

export interface EnrollUsersPayload {
    userIds: UUID[];
    status: SeatAssignmentStatus;
}

export const emptyEnrollUsersPayload: EnrollUsersPayload = {
    userIds: [],
    status: SeatAssignmentStatus.PendingApproval,
};
