import { motion } from 'framer-motion';
import React, { Key, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';

import { TestKeyAware } from '../../types';
import { Box, Paragraph3, Pressable } from '../base';
import { Icon } from '../icon';

export interface TabProps<T extends Key> extends TestKeyAware {
    id: T;
    icon?: Svg;
    label: string;
    rightSlot?: ReactNode;
    disabled?: boolean;

    // eslint-disable-next-line react/no-unused-prop-types
    divider?: boolean;
    // Props from parent, you should not pass them manually
    active?: boolean;
    onClick?(id: T): void;
}

export const Tab = <T extends Key>({
    id,
    icon,
    label,
    rightSlot,
    disabled,
    active,
    onClick,
    testKey,
}: TabProps<T>) => {
    return (
        <Box
            paddingBottom={8}
            relative
            // Slightly increase z-index to make sure the line is on top of e.g. table header
            zIndex={3}
        >
            <TabPressable
                onClick={() => !disabled && onClick?.(id)}
                active={active}
                inactive={disabled}
                activeEffect
                row
                gap={8}
                paddingHorizontal={8}
                paddingVertical={4}
                rounded={8}
                color={active ? Color.InteractionDefaultNormal : Color.InteractionNeutralNormal}
                data-test-key={testKey}
            >
                {icon && <Icon svg={icon} size={16} color='currentColor' />}
                <Paragraph3 color='currentColor' bold lineHeight='large'>
                    {label}
                </Paragraph3>
                {rightSlot}
            </TabPressable>
            {active && (
                <Line
                    bg={Color.InteractionDefaultNormal}
                    height={3}
                    rounded
                    as={motion.div}
                    layoutId='tabs-line'
                />
            )}
        </Box>
    );
};

const TabPressable = styled(Pressable)<{ active?: boolean }>`
    ${p =>
        !(p.active || p.inactive) &&
        css`
            &:hover {
                background-color: ${Color.InteractionNeutralSubtleHover};
            }
        `}
`;

const Line = styled(Box)`
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
`;
