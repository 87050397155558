import { emptyEnrollUsersPayload, EnrollUsersPayload } from '@hofy/api-admin';
import { SeatAssignmentStatus } from '@hofy/api-shared';
import { minLength, useForm, useToast, validator } from '@hofy/ui';

interface EnrollUsersMutation {
    mutate(payload: EnrollUsersPayload): void;
    isPending: boolean;
    isError: boolean;
}

export const useEnrollUsersForm = (mutation: EnrollUsersMutation) => {
    const { showToast } = useToast();
    const form = useForm<EnrollUsersPayload>({
        initial: {
            ...emptyEnrollUsersPayload,
            status: SeatAssignmentStatus.Active,
        },
        onSubmit: mutation.mutate,
        validate: validator<EnrollUsersPayload>({
            userIds: minLength('At least 1 user to be provided', 1),
        }),
        onInvalid(error) {
            if (error.userIds) {
                showToast({
                    type: 'negative',
                    message: error.userIds.toString(),
                });
            }
        },
    });

    return {
        isLoading: mutation.isPending,
        isError: mutation.isError,
        form,
    };
};

export type UseEnrollUserForm = ReturnType<typeof useEnrollUsersForm>;
