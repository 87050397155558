import { useMutation } from '@tanstack/react-query';

import { byodOrderService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useDownloadBYODOrderDocument = () => {
    const mutation = useMutation({
        mutationFn: (p: { byodOrderId: UUID; fileId: UUID }) =>
            byodOrderService.downloadBYODOrderDocument(p.byodOrderId, p.fileId),
    });
    const download = (byodOrderId: UUID, fileId: UUID) => {
        mutation.mutate({ byodOrderId, fileId });
    };

    return {
        download,
        isDownloading: mutation.isPending,
        isDownloadError: mutation.isError,
    };
};
