import React, { FC } from 'react';

import {
    allOrganizationTypes,
    getPlatformTierOptions,
    OrganizationType,
    PlatformTier,
    prodOrganizationType,
    Role,
} from '@hofy/api-shared';
import { RadioGroup } from '@hofy/common';
import { isProd } from '@hofy/config';
import { coreCurrencies } from '@hofy/global';
import { useTrCurrency } from '@hofy/i18n';
import {
    Alert,
    Box,
    FormCheckbox,
    FormContainer,
    FormInput,
    FormSelect,
    FormSwitch,
    LabeledSelect,
    Paragraph3,
    UseForm,
} from '@hofy/ui';

import { FormAdminSelect } from '../../../components/domain/admin/FormAdminSelect';
import { useOrganizationContractsFlag } from '../../../store/organizationContracts/useOrganizationContractsFlag';
import { CreateOrganizationFormData } from '../../../store/organizations/useCreateOrganization';
import { useTrOrganizationType } from '../../../store/organizations/useTrOrganizationType';
import { useTrPlatformTier } from '../../../store/organizations/useTrPlatformTier';
import { ManagerForm } from './ManagerForm';

interface CreateOrganizationFormProps {
    form: UseForm<CreateOrganizationFormData>;
}

export const CreateOrganizationForm: FC<CreateOrganizationFormProps> = ({ form }) => {
    const trCurrency = useTrCurrency();
    const trOrganizationType = useTrOrganizationType();
    const trPlatformTier = useTrPlatformTier();

    const [organizationContractsEnabled] = useOrganizationContractsFlag();
    const platformTierOptions = getPlatformTierOptions(
        form.values.organizationType,
        organizationContractsEnabled,
    );

    const setOrganizationType = (organizationType: OrganizationType) => {
        if (organizationType === OrganizationType.Standard) {
            form.setValues({
                organizationType,
                platformTier: PlatformTier.OrgBasedPro,
            });
        } else if (organizationType === OrganizationType.Partner) {
            form.setValues({
                organizationType,
                createManager: false,
                platformTier: PlatformTier.UserBasedPro,
            });
        } else if (organizationType === OrganizationType.DeelIT) {
            form.setValues({
                organizationType,
                createManager: false,
                platformTier: PlatformTier.OrgBasedPro,
            });
        } else {
            form.setValues({
                organizationType,
                createManager: true,
                platformTier: PlatformTier.OrgBasedPro,
            });
        }
    };

    const getOrganizationTypeWithModelLabel = (type: OrganizationType) => {
        const model = type === OrganizationType.Partner ? '(bundle)' : '(unbundle)';

        return (
            <Paragraph3>
                {trOrganizationType(type)} {model}
            </Paragraph3>
        );
    };

    return (
        <FormContainer marginTop={40} marginBottom={40}>
            <FormInput label='Organization name' api={form.fields.name} isRequired />
            <FormSelect
                options={coreCurrencies}
                label='Organization currency'
                api={form.fields.currency}
                toText={trCurrency}
                isRequired
            />
            <RadioGroup
                items={isProd() ? prodOrganizationType : allOrganizationTypes}
                value={form.values.organizationType}
                onChange={setOrganizationType}
                row
                orientation='vertical'
                spacing={20}
                alignItems='flex-start'
                labelFormatter={v => getOrganizationTypeWithModelLabel(v)}
            />

            <LabeledSelect
                label='Platform tier'
                toText={trPlatformTier}
                value={form.values.platformTier}
                onChange={platformTier =>
                    form.setValues({
                        platformTier,
                    })
                }
                isRequired
                options={platformTierOptions}
                disabled={platformTierOptions.length <= 1}
            />

            {form.values.organizationType === OrganizationType.Standard && (
                <StandardOrganizationForm form={form} />
            )}
            {form.values.organizationType === OrganizationType.Demo && <DemoOrganizationForm form={form} />}
            {form.values.organizationType !== OrganizationType.DeelIT && (
                <>
                    <FormAdminSelect
                        role={Role.SalesAdmin}
                        label='Sales representative'
                        api={form.fields.salesAdminId}
                    />
                    <FormAdminSelect
                        role={Role.SuccessAdmin}
                        label='Account manager'
                        api={form.fields.successAdminId}
                    />
                </>
            )}
        </FormContainer>
    );
};

interface DemoOrganizationFormProps {
    form: UseForm<CreateOrganizationFormData>;
}

const DemoOrganizationForm: FC<DemoOrganizationFormProps> = ({ form }) => {
    return (
        <>
            <Alert
                type='informative'
                title='Default teams will be generated'
                description="These teams will be approved to use an initial collection of products. Created managers will be assigned to the managers' team."
            />
            <ManagerForm form={form} />
        </>
    );
};

interface StandardOrganizationFormProps {
    form: UseForm<CreateOrganizationFormData>;
}

const StandardOrganizationForm: FC<StandardOrganizationFormProps> = ({ form }) => {
    return (
        <>
            <Alert
                type='informative'
                title='Default teams will be generated'
                description="These teams will be approved to use an initial collection of products. Created managers will be assigned to the managers' team."
            />
            <FormCheckbox
                label='Generate seed data'
                api={form.fields.seedProducts}
                onChange={seedProducts => {
                    form.setValues({
                        seedWithFurniture: seedProducts && form.values.seedWithFurniture,
                    });
                }}
            />
            <Box marginLeft={30}>
                <FormCheckbox
                    label='Include furniture'
                    api={form.fields.seedWithFurniture}
                    disabled={!form.values.seedProducts}
                />
            </Box>
            <FormSwitch label='Create Manager' api={form.fields.createManager} />
            {form.values.createManager && <ManagerForm form={form} />}
        </>
    );
};
