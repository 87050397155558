import React, { FC } from 'react';

import { UpdateOrganizationFinancialSettingsPayload } from '@hofy/api-admin';
import {
    bundledPlatformTiers,
    PlatformTier,
    PlatformTierConfigDto,
    PlatformTierConfigFeaturesMap,
    unbundledPlatformTiers,
} from '@hofy/api-shared';
import { FormRow } from '@hofy/common';
import {
    FormGridRow,
    FormSection,
    LabeledCheckbox,
    LabeledNumberInput,
    LabeledSelect,
    Paragraph3,
    UseForm,
} from '@hofy/ui';
import { DisabledWithTooltipWrapper } from '@hofy/ui-domain/src/permission/DisabledWithTooltipWrapper';

import { useAdminI18n } from '../../../../i18n/useAdminI18n';
import { useTrPlatformTier } from '../../../../store/organizations/useTrPlatformTier';

interface PlatformTierConfigFormSectionProps {
    form: UseForm<UpdateOrganizationFinancialSettingsPayload>;
    platformTierConfigFeatures: PlatformTierConfigFeaturesMap;
}

export const PlatformTierFormSection: FC<PlatformTierConfigFormSectionProps> = ({
    form,
    platformTierConfigFeatures,
}) => {
    const { tr } = useAdminI18n();
    const trPlatformTier = useTrPlatformTier();
    const platformTierConfig = form.values.platformTierConfig;
    const handleStateChange = (value: Partial<PlatformTierConfigDto>) => {
        form.setValues({
            platformTierConfig: {
                ...platformTierConfig,
                ...value,
            },
        });
    };

    const handlePlatformTierChange = (platformTier: PlatformTier) => {
        handleStateChange({
            platformTier,
            ...platformTierConfigFeatures[platformTier],
        });
    };

    const isUnbundlingEnabled = form.values.unbundlingEnabled;
    const platformTierOptions = isUnbundlingEnabled ? unbundledPlatformTiers : bundledPlatformTiers;
    const isPerSeat = form.values.platformTierConfig.platformTier === PlatformTier.PerSeat;

    return (
        <FormSection label={tr('organization-page.financial-settings-update.modal.platform-tier-section')}>
            <FormRow>
                <LabeledSelect
                    label={tr('organization-page.financial-settings-update.modal.platform-tier-select')}
                    options={platformTierOptions}
                    onChange={handlePlatformTierChange}
                    value={platformTierConfig.platformTier}
                    toText={trPlatformTier}
                    disabled={platformTierConfig.platformTier == PlatformTier.PerSeat}
                />
            </FormRow>

            <DisabledWithTooltipWrapper
                disabled={isPerSeat}
                tooltip={tr('organization-page.financial-settings-update.modal.no-per-seat-limits')}
            >
                <FormGridRow columns={4}>
                    <LabeledNumberInput
                        label={tr('organization-page.financial-settings-update.modal.subsidiary-limit')}
                        nullable
                        onChange={value =>
                            handleStateChange({
                                billingEntityLimit: value || null,
                            })
                        }
                        value={platformTierConfig.billingEntityLimit || null}
                    />
                    <LabeledNumberInput
                        label={tr('organization-page.financial-settings-update.modal.team-limit')}
                        nullable
                        onChange={value =>
                            handleStateChange({
                                teamLimit: value || null,
                            })
                        }
                        value={platformTierConfig.teamLimit || null}
                    />
                    {isUnbundlingEnabled && (
                        <LabeledNumberInput
                            label={tr('organization-page.financial-settings-update.modal.device-limit')}
                            nullable
                            onChange={value =>
                                handleStateChange({
                                    deviceTrackedLimit: value || null,
                                })
                            }
                            value={platformTierConfig.deviceTrackedLimit || null}
                        />
                    )}

                    {platformTierConfig.platformTier === PlatformTier.OrgBasedFree && (
                        <LabeledNumberInput
                            label={tr('organization-page.financial-settings-update.modal.laptop-limit')}
                            nullable
                            onChange={value =>
                                handleStateChange({
                                    teamMemberEquipLimit: value || null,
                                })
                            }
                            value={platformTierConfig.teamMemberEquipLimit || null}
                        />
                    )}
                </FormGridRow>
            </DisabledWithTooltipWrapper>

            <FormGridRow columns={4}>
                <DisabledWithTooltipWrapper
                    disabled={isPerSeat}
                    tooltip={tr('organization-page.financial-settings-update.modal.per-seat-saml')}
                >
                    <LabeledCheckbox
                        label={<Paragraph3>SAML</Paragraph3>}
                        checked={platformTierConfig.samlEnabled}
                        onChange={value =>
                            handleStateChange({
                                samlEnabled: value,
                            })
                        }
                    />
                </DisabledWithTooltipWrapper>
                <DisabledWithTooltipWrapper
                    disabled={isPerSeat}
                    tooltip={tr('organization-page.financial-settings-update.modal.paid-seat-only')}
                >
                    <LabeledCheckbox
                        label={<Paragraph3>Auto approval</Paragraph3>}
                        checked={platformTierConfig.autoApprovalEnabled}
                        onChange={value =>
                            handleStateChange({
                                autoApprovalEnabled: value,
                            })
                        }
                    />
                </DisabledWithTooltipWrapper>
                <DisabledWithTooltipWrapper
                    disabled={isPerSeat}
                    tooltip={tr('organization-page.financial-settings-update.modal.paid-seat-only')}
                >
                    <LabeledCheckbox
                        label={<Paragraph3>HRIS Import Rules</Paragraph3>}
                        checked={platformTierConfig.hrisAutoImportEnabled}
                        onChange={value =>
                            handleStateChange({
                                hrisAutoImportEnabled: value,
                            })
                        }
                    />
                </DisabledWithTooltipWrapper>
                <DisabledWithTooltipWrapper
                    disabled={isPerSeat}
                    tooltip={tr('organization-page.financial-settings-update.modal.deprecated-setting')}
                >
                    <LabeledCheckbox
                        label={<Paragraph3>Manager only organization</Paragraph3>}
                        checked={platformTierConfig.managerOnlyOrganizationEnabled}
                        onChange={managerOnlyOrganizationEnabled =>
                            handleStateChange({
                                managerOnlyOrganizationEnabled,
                            })
                        }
                    />
                </DisabledWithTooltipWrapper>
                {!isUnbundlingEnabled && (
                    <LabeledCheckbox
                        label={<Paragraph3>5 Free Licenses</Paragraph3>}
                        checked={platformTierConfig.freeLicensesLimit > 0}
                        onChange={value =>
                            handleStateChange({
                                freeLicensesLimit: value ? 5 : 0,
                            })
                        }
                    />
                )}
            </FormGridRow>
        </FormSection>
    );
};
