import React, { FC } from 'react';
import styled from 'styled-components';

import { ShipmentClass, ShipmentCourier, useTrShipmentCourier } from '@hofy/api-shared';
import { Box, Labeled, LabeledText, Link, Paragraph3 } from '@hofy/ui';

import { useTrAdminShipmentClass } from '../../../store/shipments/useTrAdminShipmentClass';

interface CourierDetailsProps {
    courier: ShipmentCourier | null;
    shipmentClass: ShipmentClass;
    courierReference: string | null;
    trackingLink: string | null;
}

export const getTrackingLink = (link: string | null) => {
    if (!link) {
        return <Paragraph3>--</Paragraph3>;
    }
    return <Link to={link} />;
};

export const CourierDetails: FC<CourierDetailsProps> = ({
    courier,
    shipmentClass,
    trackingLink,
    courierReference,
}) => {
    const trClass = useTrAdminShipmentClass();
    const trCourier = useTrShipmentCourier();
    return (
        <>
            <Box row>
                <LabeledText flex={2} label='Courier' content={courier ? trCourier(courier) : null} />
                <LabeledText flex={1} label='Type' content={trClass(shipmentClass)} />
            </Box>
            <LabeledText label='Tracking Reference' content={courierReference} />
            <Labeled
                label='Tracking link'
                content={<EllipsisText>{getTrackingLink(trackingLink)}</EllipsisText>}
            />
        </>
    );
};

const EllipsisText = styled(Box)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
