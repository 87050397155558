import React, { FC } from 'react';

import { Tab, Tabs } from '@hofy/ui';

import { useOrganizationContractsFlag } from '../../store/organizationContracts/useOrganizationContractsFlag';
import { allOrganizationTabs, OrganizationTab } from '../../store/organizations/types/OrganizationTab';
import { useTrOrganizationTab } from '../../store/organizations/useTrOrganizationTab';

interface OrganizationDetailsPageTabsProps {
    tab: OrganizationTab;

    onChange(tab: OrganizationTab): void;

    unbundlingEnabled: boolean;
    perSeat: boolean;
}

export const OrganizationDetailsPageTabs: FC<OrganizationDetailsPageTabsProps> = ({
    tab,
    onChange: onChangeTab,
    unbundlingEnabled,
    perSeat,
}) => {
    const trTab = useTrOrganizationTab();
    const [organizationContractsEnabled] = useOrganizationContractsFlag();

    const organizationTabs = allOrganizationTabs.filter(
        tab => tab !== OrganizationTab.OrganizationContracts || organizationContractsEnabled,
    );

    return (
        <Tabs active={tab} onChange={onChangeTab}>
            {organizationTabs.map(tab => (
                <Tab
                    key={tab}
                    id={tab}
                    label={trTab(tab)}
                    disabled={
                        (tab === OrganizationTab.Discounts && unbundlingEnabled) ||
                        (tab === OrganizationTab.Subscriptions && perSeat) ||
                        (tab == OrganizationTab.OrganizationContracts && !perSeat)
                    }
                />
            ))}
        </Tabs>
    );
};
