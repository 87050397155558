import React, { FC } from 'react';

import { OrganizationDetailsDto, SubscriptionDto } from '@hofy/api-admin';
import { platformTierIsOrgBased } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, Button, FormContainer, SvgIcon } from '@hofy/ui';

import { useCreateSubscription } from '../../../store/subscription/useCreateSubscription';
import { SubscriptionFormFields } from '../components/SubscriptionFormFields';

interface CreateSubscriptionSlideoutProps {
    organization: OrganizationDetailsDto;
    defaultSubscription: SubscriptionDto;
    onClose(): void;
}

export const CreateSubscriptionSlideout: FC<CreateSubscriptionSlideoutProps> = ({
    organization,
    defaultSubscription,
    onClose,
}) => {
    const { form, isLoading, isError } = useCreateSubscription(onClose, organization, defaultSubscription);
    const organizationBased = platformTierIsOrgBased(organization.platformTierConfig.platformTier);

    return (
        <Slideout width={600} onClose={onClose} slideoutId='add-subscription'>
            <SlideoutHeader title='Add subscription' />
            <SlideoutContent>
                <FormContainer marginVertical={40}>
                    <SubscriptionFormFields form={form} organizationBased={organizationBased} />
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <Button
                    leftIcon={SvgIcon.Cross}
                    label='Cancel'
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                />
                <AsyncButton
                    label='Save'
                    onClick={form.submit}
                    isLoading={isLoading}
                    isError={isError}
                    disableCheck
                />
            </SlideoutFooter>
        </Slideout>
    );
};
