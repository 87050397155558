import React, { FC } from 'react';

import { OrganizationDetailsDto, SubscriptionDetailsDto, useTrSubscriptionTerm } from '@hofy/api-admin';
import { platformTierIsOrgBased, useTrPaymentSchema } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { usePrice, useReadableDate } from '@hofy/hooks';
import {
    Alert,
    AsyncButton,
    Button,
    FormContainer,
    FormGridRow,
    FormSection,
    LabeledText,
    SvgIcon,
} from '@hofy/ui';

import { useReplaceSubscription } from '../../../store/subscription/useReplaceSubscription';
import { SubscriptionFormFields } from '../components/SubscriptionFormFields';

interface ReplaceSubscriptionSlideoutProps {
    organization: OrganizationDetailsDto;
    subscription: SubscriptionDetailsDto;
    onClose(): void;
}

export const ReplaceSubscriptionSlideout: FC<ReplaceSubscriptionSlideoutProps> = ({
    organization,
    subscription,
    onClose,
}) => {
    const { form, isLoading, isError } = useReplaceSubscription(organization, subscription, onClose);
    const organizationBased = platformTierIsOrgBased(organization.platformTierConfig.platformTier);

    return (
        <Slideout width={600} onClose={onClose} slideoutId='replace-subscription'>
            <SlideoutHeader title='Replace subscription' />
            <SlideoutContent>
                <Alert
                    type='warning'
                    title='This action will end the current subscription and create a new subscription'
                    marginVertical={30}
                    description='Please note, the invoice entries already generated will NOT be automatically credited and need to be handled manually.'
                />
                <FormSection label='Current subscription' marginTop={20}>
                    <SubscriptionDetails subscription={subscription} />
                </FormSection>
                <FormSection label='New subscription' marginTop={20}>
                    <SubscriptionFormFields form={form} organizationBased={organizationBased} />
                </FormSection>
                <Alert
                    type='warning'
                    title='New subscription end date'
                    marginVertical={30}
                    description='The new subscription end date will be calculated based on the start date of the current subscription and the new subscription term added.'
                />
            </SlideoutContent>
            <SlideoutFooter>
                <Button
                    leftIcon={SvgIcon.Cross}
                    label='Cancel'
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                />
                <AsyncButton
                    label='Save'
                    onClick={form.submit}
                    isLoading={isLoading}
                    isError={isError}
                    disableCheck
                />
            </SlideoutFooter>
        </Slideout>
    );
};

interface SubscriptionDetailsProps {
    subscription: SubscriptionDetailsDto;
}

const SubscriptionDetails: FC<SubscriptionDetailsProps> = ({ subscription }) => {
    const { formatPriceWithISOCode: formatPrice } = usePrice();
    const trPaymentSchema = useTrPaymentSchema();
    const trTerm = useTrSubscriptionTerm();
    const { formatReadableDate } = useReadableDate();

    return (
        <FormContainer flex={1}>
            <FormGridRow columns={2}>
                <LabeledText label='ID' content={subscription.id} />
                <LabeledText
                    label='Billing frequency'
                    content={trPaymentSchema(subscription.billingFrequency)}
                />
                <LabeledText label='Term' content={trTerm(subscription.term)} />
                <LabeledText label='Price' content={formatPrice(subscription.price)} />
                <LabeledText label='Start on' content={formatReadableDate(subscription.startOn)} />
                <LabeledText label='End on' content={formatReadableDate(subscription.endOn)} />
                <LabeledText
                    label='Total generated invoice entries value'
                    content={formatPrice(subscription.invoiceEntriesTotalValue)}
                />
            </FormGridRow>
        </FormContainer>
    );
};
