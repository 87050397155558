import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';

import { getBYODOrderLink } from '../../../components/routing/adminLinks';
import { Link } from '../../../components/routing/Link';

interface BYODOrdersLinkProps {
    id: UUID;
    children?: ReactNode;
}

export const BYODOrdersLink: FC<BYODOrdersLinkProps> = ({ id, children }) => {
    return <Link to={getBYODOrderLink(id)}>{children}</Link>;
};
