import { useHistory } from 'react-router-dom';

import { UUID } from '@hofy/global';

import { OrganizationContractTab } from '../contracts/types/OrganizationContractTab';

export const useNavigateOrganizationContract = (rootPath: string) => {
    const history = useHistory();

    const getCreateOrganizationContractLink = () => `${rootPath}/create`;
    const handleOpenDetailsOrganizationContract = (id: UUID) =>
        history.push(`${rootPath}/${id}/${OrganizationContractTab.Details}`);
    const getEnrollUsersLink = (id: UUID) => `${rootPath}/${id}/${OrganizationContractTab.Seats}/create`;
    const handleOrganizationContractTab = (id: UUID, tab: OrganizationContractTab) =>
        history.replace(`${rootPath}/${id}/${tab}`);
    const handleOpenOrganizationContractTab = () => history.goBack();
    return {
        getEnrollUsersLink,
        getCreateOrganizationContractLink,
        handleOpenDetailsOrganizationContract,
        handleOrganizationContractTab,
        handleOpenOrganizationContractTab,
    };
};
