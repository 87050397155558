export const organizationDetailsUpdateI18n = {
    'organization-page.details-update.title': 'Update organization details',
    'organization-page.details-update.subtitle': 'This operation will update the organization details.',
    'organization-page.details-update.modal.confirm': 'Save',
    'organization-page.details-update.modal.cancel': 'Cancel',
    'organization-page.details-update.modal.organization-size': 'Organization size',
    'organization-page.details-update.modal.organization-name': 'Organization name',
    'organization-page.details-update.modal.logo-section': 'Logo',
    'organization-page.details-update.modal.sales-section': 'Sales attribution',
    'organization-page.details-update.modal.sales-representative': 'Sales representative',
    'organization-page.details-update.modal.account-manager': 'Account manager',
};
