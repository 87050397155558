import { Country, Price } from '@hofy/global';

import { CustomerOwnedStoreAndReuseLocation } from './CustomerOwnedStoreAndReuseLocation';

export interface CustomerOwnedStoreAndReuseFeesDto {
    localFee: CustomerOwnedStoreAndReuseFee | null;
    hubFee: CustomerOwnedStoreAndReuseFee | null;
}

export interface CustomerOwnedStoreAndReuseFee {
    activationFee: Price;
    monthlyFee: Price;
    destinationCountry: Country;
    gracePeriodInMonths: number;
}

export const customerOwnedStoreAndReuseActivationFeeFromOption = (
    { localFee, hubFee }: CustomerOwnedStoreAndReuseFeesDto,
    storeAndReuseCollectToLocation: CustomerOwnedStoreAndReuseLocation | null,
): Price | null => {
    if (storeAndReuseCollectToLocation === null) {
        return null;
    }
    if (storeAndReuseCollectToLocation === CustomerOwnedStoreAndReuseLocation.LocalHub) {
        return localFee?.activationFee ?? null;
    } else {
        return hubFee?.activationFee ?? null;
    }
};

export const customerOwnedStoreAndReuseMonthlyFeeFromOption = (
    { localFee, hubFee }: CustomerOwnedStoreAndReuseFeesDto,
    storeAndReuseCollectToLocation: CustomerOwnedStoreAndReuseLocation | null,
): Price | null => {
    if (storeAndReuseCollectToLocation === null) {
        return null;
    }
    if (storeAndReuseCollectToLocation === CustomerOwnedStoreAndReuseLocation.LocalHub) {
        return localFee?.monthlyFee ?? null;
    } else {
        return hubFee?.monthlyFee ?? null;
    }
};

export const customerOwnedGracePeriodInMonthsFromOption = (
    { localFee, hubFee }: CustomerOwnedStoreAndReuseFeesDto,
    storeAndReuseCollectToLocation: CustomerOwnedStoreAndReuseLocation | null,
): number | null => {
    if (storeAndReuseCollectToLocation === null) {
        return null;
    }
    if (storeAndReuseCollectToLocation === CustomerOwnedStoreAndReuseLocation.LocalHub) {
        return localFee?.gracePeriodInMonths ?? null;
    } else {
        return hubFee?.gracePeriodInMonths ?? null;
    }
};
