import { ContractExpirySettingsPayload, ContractSettingPayload, OrganizationRefDto } from '@hofy/api-shared';
import { DateString, PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { AdminOrganizationsFilters } from './types/AdminOrganizationsFilters';
import { CreateOrganizationPayload } from './types/CreateOrganizationPayload';
import { DisconnectOrganizationHRISPayload } from './types/DisconnectOrganizationHRISPayload';
import { InvoiceGroupsPayload } from './types/InvoiceGroupsPayload';
import { OrganizationDiscountPayload } from './types/OrganizationDiscountPayload';
import {
    OrganizationDetailsDto,
    OrganizationDto,
    OrganizationTermAndConditionDto,
} from './types/OrganizationDto';
import { OrganizationPaymentDiscountsPayload } from './types/OrganizationPaymentDiscountsPayload';
import { UpdateDataErasureSettingsPayload } from './types/UpdateDataErasureSettingsPayload';
import { UpdateOrganizationBYODPayload } from './types/UpdateOrganizationBYODPayload';
import { UpdateOrganizationCurrencyPayload } from './types/UpdateOrganizationCurrencyPayload';
import { UpdateOrganizationDeelPayload } from './types/UpdateOrganizationDeelPayload';
import { UpdateOrganizationDetailsPayload } from './types/UpdateOrganizationDetailsPayload';
import { UpdateOrganizationFeaturesPayload } from './types/UpdateOrganizationFeaturesPayload';
import { UpdateOrganizationFinancialSettingsPayload } from './types/UpdateOrganizationFinancialSettingsPayload';
import { UpdateOrganizationMdmPayload } from './types/UpdateOrganizationMdmPayload';
import { UpdateOrganizationStatusPayload } from './types/UpdateOrganizationStatusPayload';
import { UpdateOrganizationStoreAndReusePayload } from './types/UpdateOrganizationStoreAndReusePayload';

interface OrganizationTermAndConditionsResponse {
    organizationTermAndConditions: OrganizationTermAndConditionDto[];
}

export interface OrganizationTermAndConditionPayload {
    startOn: DateString | null;
}

class OrganizationService {
    public updateInvoiceGroups = async (organizationId: UUID, payload: InvoiceGroupsPayload) => {
        return restClient.post(`/api/admin/organizations/${organizationId}/invoice-groups`, payload);
    };

    public updateContractExpirySettings = async (
        organizationId: UUID,
        payload: ContractExpirySettingsPayload,
    ) => {
        return restClient.putJson(
            `/api/admin/organizations/${organizationId}/contract-expiry-settings`,
            payload,
        );
    };
    public listOrganizations = async (
        filters: AdminOrganizationsFilters,
        page: PageRequest,
    ): Promise<PageResponse<OrganizationDto>> => {
        return await restClient.getJson<PageResponse<OrganizationDto>>(
            stringifyUrl({
                url: '/api/admin/organizations',
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public downloadListOrganizationsReport = (): Promise<void> => {
        return restClient.downloadFile('/api/admin/organizations/report').then(downloadFileFromResponse);
    };

    public getOrganizationRefs = async (
        selectedOrgs: UUID[],
        search: string,
    ): Promise<OrganizationRefDto[]> => {
        return await restClient.getJson<OrganizationRefDto[]>(
            stringifyUrl({
                url: '/api/admin/organizations/refs',
                query: {
                    selectedOrgs,
                    search: search,
                },
            }),
        );
    };

    public getOrganization = (id: UUID): Promise<OrganizationDetailsDto> => {
        return restClient.getJson<OrganizationDetailsDto>(`/api/admin/organizations/${id}`);
    };

    public deleteOrganization = (id: UUID): Promise<void> => {
        return restClient.delete(`/api/admin/organizations/${id}`).then();
    };

    public createOrganization = async (payload: CreateOrganizationPayload): Promise<number> => {
        return restClient.postJson<{ id: number }>('/api/admin/organizations', payload).then(r => r.id);
    };

    public disconnectOrganizationHRIS = async (id: UUID, payload: DisconnectOrganizationHRISPayload) => {
        return restClient.post(`/api/admin/organizations/${id}/disconnect-hris`, payload);
    };

    public updateOrganizationFinancialSettings = async (
        organizationId: UUID,
        payload: UpdateOrganizationFinancialSettingsPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/financial-settings`, payload);
    };

    public updateOrganizationFeatures = async (
        organizationId: UUID,
        payload: UpdateOrganizationFeaturesPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/features`, payload);
    };

    public updateOrganizationMdm = async (
        organizationId: UUID,
        payload: UpdateOrganizationMdmPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/mdm`, payload);
    };

    public updateOrganizationBYOD = async (
        organizationId: UUID,
        payload: UpdateOrganizationBYODPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/byod`, payload);
    };

    public updateOrganizationStoreAndReuse = async (
        organizationId: UUID,
        payload: UpdateOrganizationStoreAndReusePayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/store-and-reuse`, payload);
    };

    public updateOrganizationStatus = async (
        organizationId: UUID,
        payload: UpdateOrganizationStatusPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/status`, payload);
    };

    public updateOrganizationDetails = async (
        organizationId: UUID,
        payload: UpdateOrganizationDetailsPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/details`, payload);
    };

    public updateOrganizationDataErasureSettings = async (
        organizationId: UUID,
        payload: UpdateDataErasureSettingsPayload,
    ): Promise<number> => {
        return restClient.putJson(
            `/api/admin/organizations/${organizationId}/data-erasure-settings`,
            payload,
        );
    };

    public updateOrganizationCurrency = async (
        organizationId: UUID,
        payload: UpdateOrganizationCurrencyPayload,
    ): Promise<void> => {
        return restClient.patchJson(`/api/admin/organizations/${organizationId}/currency`, payload);
    };

    public updateOrganizationDeelData = async (
        id: UUID,
        payload: UpdateOrganizationDeelPayload,
    ): Promise<void> => {
        return restClient.patch(`/api/admin/organizations/${id}/deel`, payload);
    };

    public createOrganizationDiscount = async (
        organizationId: UUID,
        payload: OrganizationDiscountPayload,
    ) => {
        return restClient.post(`/api/admin/organizations/${organizationId}/discounts`, payload);
    };

    public updateOrganizationDiscount = async (
        organizationId: UUID,
        discountId: UUID,
        payload: OrganizationDiscountPayload,
    ) => {
        return restClient.put(`/api/admin/organizations/${organizationId}/discounts/${discountId}`, payload);
    };

    public deleteOrganizationDiscount = async (id: UUID) => {
        return restClient.delete(`/api/admin/organizations/discounts/${id}`);
    };

    public deleteOrganizationTermAndCondition = async (id: UUID) => {
        return restClient.delete(`/api/admin/organizations/term-and-condition/${id}`);
    };

    public updateOrganizationContractSettings = async (
        id: UUID,
        settingsId: UUID,
        contractSettings: ContractSettingPayload,
    ) => {
        return restClient.post(
            `/api/admin/organizations/${id}/contract-settings/${settingsId}`,
            contractSettings,
        );
    };

    public accountingSync = async (organizationId: UUID) => {
        return restClient.post(`/api/admin/organizations/${organizationId}/accounting-sync`);
    };

    public syncToZendesk = async (organizationId: UUID) => {
        return restClient.post(
            stringifyUrl({
                url: `/api/admin/organizations/sync-zendesk`,
                query: {
                    organizationId,
                },
            }),
        );
    };

    public updateOrganizationPaymentDiscounts = async (
        organizationId: UUID,
        payload: OrganizationPaymentDiscountsPayload,
    ) => {
        return restClient.put(`/api/admin/organizations/${organizationId}/payment-discounts`, payload);
    };

    public listOrganizationTermAndConditions = async (
        orgId: UUID,
    ): Promise<OrganizationTermAndConditionDto[]> => {
        const result = await restClient.getJson<OrganizationTermAndConditionsResponse>(
            `/api/admin/organizations/${orgId}/terms-and-conditions`,
        );
        return result.organizationTermAndConditions;
    };

    public addOrganizationTermAndCondition = async (
        organizationId: UUID,
        termAndConditionId: UUID,
        payload: OrganizationTermAndConditionPayload,
    ) => {
        return restClient.post(
            `/api/admin/organizations/${organizationId}/term-and-condition/${termAndConditionId}`,
            payload,
        );
    };
}

export const organizationService = new OrganizationService();
