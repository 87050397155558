import { minBy } from 'lodash-es';

import { decimalToNumber, Price } from '@hofy/global';

import { RentalStoreAndReuseLocation } from './RentalStoreAndReuseLocation';

export interface RentalStoreAndReuseFeesDto {
    localFee: RentalStoreAndReuseFee | null;
    hubFee: RentalStoreAndReuseFee | null;
    zone1CountryCount: number;
}

interface RentalStoreAndReuseFee {
    activationFee: Price;
}

export const rentalStoreAndReuseActivationFeeFromOption = (
    { localFee, hubFee }: RentalStoreAndReuseFeesDto,
    storeAndReuseCollectToLocation: RentalStoreAndReuseLocation | null,
): Price | null => {
    if (storeAndReuseCollectToLocation === null) {
        return null;
    }
    if (storeAndReuseCollectToLocation === RentalStoreAndReuseLocation.Local) {
        return localFee?.activationFee ?? null;
    } else {
        return hubFee?.activationFee ?? null;
    }
};

export const minActivationFee = (fees: RentalStoreAndReuseFeesDto | null): Price | null => {
    return (
        (fees &&
            minBy([fees.localFee, fees.hubFee].filter(fee => fee !== null) as RentalStoreAndReuseFee[], fee =>
                decimalToNumber(fee.activationFee.amount),
            )?.activationFee) ||
        null
    );
};
