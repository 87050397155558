import React, { ElementRef, forwardRef, useEffect, useState } from 'react';

import { DateRangeName, DateRangeStrings, DateRangeType, DateString } from '@hofy/global';
import {
    dateRangeToRangeType,
    DateType,
    formatDateRange,
    parseDateRange,
    parseDateTime,
    parseOptionalDateTime,
    parseUserDate,
} from '@hofy/helpers';
import { useTrDateRangeType } from '@hofy/i18n';

import { TestKeyAware } from '../../../../types';
import { OuterBoxProps } from '../../../base';
import { InlineDatepickerRange } from '../../../date';
import { Dropdown } from '../../../dropdown/Dropdown';
import { Icon, IconButton, SvgIcon } from '../../../icon';
import { Chevron } from '../../../shared/Chevron';
import { Input } from '../Input';

interface DateRangeInputBaseProps extends TestKeyAware, OuterBoxProps {
    type?: DateType;
    onChange(date: DateRangeStrings | null): void;
    onBlur?(): void;
    allowedRangeTypes?: DateRangeType[];

    predefinedRanges?: DateRangeName[];

    minDate?: DateString;
    maxDate?: DateString;
    filterDate?(date: DateString): boolean;

    isRequired?: boolean;
    placeholder?: string;
    isError?: boolean;

    disabled?: boolean;
    yearMonthPicker?: boolean;

    clearable?: boolean;
}

interface NormalDateRangeInputProps extends DateRangeInputBaseProps {
    nullable?: false;
    value: DateRangeStrings;
    onChange(value: DateRangeStrings): void;
}

interface NullableDateRangeInputProps extends DateRangeInputBaseProps {
    nullable: true;
    value: DateRangeStrings | null;
    onChange(value: DateRangeStrings | null): void;
}

export type DateRangeInputProps = NormalDateRangeInputProps | NullableDateRangeInputProps;

export const DateRangeInput = forwardRef<ElementRef<'input'>, DateRangeInputProps>(
    (
        {
            type,
            value,
            onChange,
            onBlur,
            allowedRangeTypes,
            predefinedRanges,
            testKey,
            minDate,
            maxDate,
            filterDate,
            nullable,
            disabled,
            yearMonthPicker,
            clearable,

            ...rest
        },
        ref,
    ) => {
        const trRangeType = useTrDateRangeType();

        const [isOpen, setIsOpen] = useState(false);
        const [input, setInput] = useState(() => formatDateRange(value));

        const empty = () => {
            if (nullable) {
                onChange(null);
            }
        };

        const min = parseOptionalDateTime(minDate);
        const max = parseOptionalDateTime(maxDate);

        const filter = (date: DateString) => {
            const parsedDate = parseDateTime(date);
            return (
                (!min || parsedDate >= min) &&
                (!max || parsedDate <= max) &&
                (!filterDate || filterDate(date))
            );
        };

        const formatInputString = (dateRange: DateRangeStrings | null): string => {
            if (!dateRange) {
                return '';
            }

            const rangeType = dateRangeToRangeType(dateRange);
            const formatted = formatDateRange(dateRange);

            if (rangeType === DateRangeType.FromToDate) {
                return formatted; // e.g.: 01 Jan 2021 - 01 Jan 2022
            }

            const fromOrTo = trRangeType(rangeType);

            return `${fromOrTo} ${formatted}`; // e.g.: After 01 Jan 2021
        };

        useEffect(() => {
            // Update input value when value changes but only if the input is not focused
            if (!isOpen) {
                setInput(formatInputString(value));
            }
        }, [value, input, isOpen]);

        const parseUserString = (userString: string): DateRangeStrings | null => {
            const fromString = trRangeType(DateRangeType.FromDate);
            const toString = trRangeType(DateRangeType.ToDate);

            // After or before prefix
            if (userString.startsWith(fromString)) {
                const dateStr = userString.replace(fromString, '').trim();
                const fromDate = parseUserDate(dateStr);
                return fromDate && filter(fromDate)
                    ? {
                          from: fromDate,
                          to: null,
                      }
                    : null;
            }

            if (userString.startsWith(toString)) {
                const dateStr = userString.replace(toString, '').trim();
                const toDate = parseUserDate(dateStr);
                return toDate && filter(toDate)
                    ? {
                          from: null,
                          to: toDate,
                      }
                    : null;
            }

            // Normal range
            const range = parseDateRange(userString);

            if (!range) {
                return null;
            }
            if ((range.from && !filter(range.from)) || (range.to && !filter(range.to))) {
                return null;
            }

            return range;
        };

        const handleDatepickerRangeChange = (date: DateRangeStrings | null): void => {
            if (!date) {
                return empty();
            }
            setInput(formatInputString(date));
            onChange(date);
        };

        const handleInputChange = (inputString: string) => {
            setInput(inputString);
            if (!inputString) {
                return empty();
            }
            const date = parseUserString(inputString);
            if (date) {
                onChange(date);
            }
        };

        const handleBlur = () => {
            setInput(formatInputString(value));
            onBlur?.();
        };

        const handleKeyDown = (event: React.KeyboardEvent<Element>): void => {
            // Close dropdown when tabbing out from the input, because we open it on focus
            // We cannot close it on blur because the datepicker is inside the dropdown and in Portal
            if (event.key === 'Tab') {
                setIsOpen(false);
            }
        };

        const clearButton = clearable && value && !disabled && (
            <IconButton icon={SvgIcon.Cross} onClick={empty} />
        );

        return (
            <Dropdown
                open={isOpen}
                onDismiss={() => setIsOpen(false)}
                contentWidth='auto'
                placement='bottom-start'
                trigger={
                    <Input
                        ref={ref}
                        value={input}
                        onChange={handleInputChange}
                        onFocus={() => setIsOpen(true)}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        testKey={testKey}
                        nullable={nullable}
                        leftSlot={<Icon svg={SvgIcon.Calendar} />}
                        rightSlot={
                            <>
                                {clearButton}
                                <Chevron isOpen={isOpen} />
                            </>
                        }
                        disabled={disabled}
                        {...rest}
                    />
                }
                asChild
                padding={24}
            >
                <InlineDatepickerRange
                    key={JSON.stringify(value)}
                    type={type}
                    allowedRangeTypes={allowedRangeTypes}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleDatepickerRangeChange}
                    onEnd={() => setIsOpen(false)}
                    filterDate={filterDate ? date => filterDate(date) : undefined}
                    predefinedRanges={predefinedRanges}
                    disabled={disabled}
                />
            </Dropdown>
        );
    },
);
