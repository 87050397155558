/** YYYY-MM-DD */
export type DateString = `${number}-${number}-${number}`;
/** YYYY-MM-DDTHH:mm:ss.SSS */
export type DateTimeString = `${number}-${number}-${number}T${number}:${number}:${number}.${number}`;

export type AnyDateString = DateString | DateTimeString;

export interface FromToDateStrings {
    from: DateString;
    to: DateString;
}

export interface FromDateStrings {
    from: DateString;
    to: null;
}

export interface ToDateStrings {
    from: null;
    to: DateString;
}

export type DateRangeStrings = FromToDateStrings | FromDateStrings | ToDateStrings;

export enum DateRangeName {
    Today = 'today',
    ThisWeek = 'this-week',
    ThisMonth = 'this-month',
    ThisQuarter = 'this-quarter',
    ThisYear = 'this-year',

    NextWeek = 'next-week',
    NextMonth = 'next-month',
    Next3Months = 'next-3-months',
    NextQuarter = 'next-quarter',
    NextYear = 'next-year',
}

export enum DateRangeType {
    FromToDate = 'FromToDate',
    FromDate = 'FromDate',
    ToDate = 'ToDate',
}
