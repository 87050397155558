import React, { FC } from 'react';

import { BookShipmentPayload, ShipmentDetailsDto } from '@hofy/api-admin';
import {
    isShipmentFromSupplierToUser,
    isShipmentFromUserToUser,
    isShipmentFromWarehouseToUser,
} from '@hofy/api-shared';
import { QuantitySelector } from '@hofy/common';
import { DateRangeType } from '@hofy/global';
import { Box, FormContainer, FormDateInput, FormDateRangeInput, FormSection, UseForm } from '@hofy/ui';

import { SendNotificationForm } from '../components/SendNotificationForm';
import { ShipmentCourierForm } from '../components/ShipmentCourierForm';
import { ShipmentScheduleForm } from '../components/ShipmentScheduleForm';

interface ShipmentBookFormProps {
    shipment: ShipmentDetailsDto;
    form: UseForm<BookShipmentPayload>;
}

export const ShipmentBookForm: FC<ShipmentBookFormProps> = ({ form, shipment }) => {
    const emailNotesTitle = isShipmentFromUserToUser(shipment)
        ? 'Processed confirmation notes for collection user'
        : 'Processed confirmation notes';

    return (
        <Box marginTop={10}>
            {!isShipmentFromUserToUser(shipment) && (
                <FormSection label='Schedule shipment' flex={1} marginBottom={20}>
                    <FormContainer>
                        <ShipmentScheduleForm form={form} />
                    </FormContainer>
                </FormSection>
            )}

            <FormSection label='Number of boxes' marginBottom={20}>
                <QuantitySelector
                    maxValue={50}
                    onChange={numberOfBoxes =>
                        form.setValues({
                            numberOfBoxes,
                        })
                    }
                    value={form.values.numberOfBoxes}
                />
            </FormSection>

            <FormSection label='External' flex={1}>
                <FormContainer>
                    <ShipmentCourierForm form={form} />
                    <FormDateInput label='Processed on' api={form.fields.bookedOn} />
                    {isShipmentFromUserToUser(shipment) && (
                        <FormDateInput
                            label='Estimated transfer collection date'
                            api={form.fields.scheduledOn}
                            nullable
                        />
                    )}
                    <FormDateRangeInput
                        label={getEstimatedDateLabel(shipment)}
                        api={form.fields.estimateOn}
                        allowedRangeTypes={[DateRangeType.FromDate, DateRangeType.FromToDate]}
                        nullable
                    />
                    <SendNotificationForm
                        form={form}
                        switchLabel='Send processed confirmation email'
                        textareaLabel={emailNotesTitle}
                    />
                </FormContainer>
            </FormSection>
        </Box>
    );
};

const getEstimatedDateLabel = (shipment: ShipmentDetailsDto) => {
    if (isShipmentFromUserToUser(shipment)) {
        return 'Estimated transfer delivery date';
    }
    if (isShipmentFromWarehouseToUser(shipment) || isShipmentFromSupplierToUser(shipment)) {
        return 'Estimated delivery date';
    }
    if (isShipmentFromWarehouseToUser(shipment)) {
        return 'Estimated collection date';
    }
    return 'Estimated date';
};
