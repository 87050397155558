import React, { FC } from 'react';

import { AddressField } from '@hofy/address';
import { ShipmentCourierPayload, ShipmentDetailsDto } from '@hofy/api-admin';
import {
    isShipmentFromHofyWarehouse,
    isShipmentFromOrganizationWarehouse,
    isShipmentFromSupplierToUser,
    isShipmentFromUser,
    isShipmentFromUserToUser,
    isShipmentFromUserToWarehouse,
    isShipmentFromWarehouseToUser,
    isShipmentToHofyWarehouse,
    isShipmentToUser,
    ShipmentStatus,
} from '@hofy/api-shared';
import {
    QuantitySelector,
    Slideout,
    SlideoutContent,
    SlideoutFooter,
    SlideoutHeader,
    Switch,
} from '@hofy/common';
import { DateRangeType } from '@hofy/global';
import {
    AsyncButton,
    FormContainer,
    FormDateInput,
    FormDateRangeInput,
    FormSection,
    UseForm,
    useFormObjectOptionalField,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { WarehouseDropdown } from '../../../components/domain/warehouses/WarehouseDropdown';
import { useUpdateShipment } from '../../../store/shipments/useUpdateShipment';
import { ShipmentCourierForm } from '../components/ShipmentCourierForm';
import { ShipmentScheduleForm } from '../components/ShipmentScheduleForm';

interface UpdateShipmentSlideoutProps {
    shipment: ShipmentDetailsDto;
    onClose(): void;
}

export const UpdateShipmentSlideout: FC<UpdateShipmentSlideoutProps> = ({ onClose, shipment }) => {
    const { form, isLoading } = useUpdateShipment(shipment, onClose);
    const fromAddress = useFormObjectOptionalField(form.fields.fromAddress);
    const toAddress = useFormObjectOptionalField(form.fields.toAddress);

    return (
        <Slideout width={600} onClose={onClose} slideoutId='update-shipment'>
            <SlideoutHeader title='Update shipment details' />
            <SlideoutContent column gap={20} paddingVertical={20}>
                {(isShipmentFromHofyWarehouse(shipment) || isShipmentToHofyWarehouse(shipment)) && (
                    <FormSection label='Warehouse details'>
                        <WarehouseDropdown
                            label='Warehouse'
                            isRequired
                            onChange={warehouseId =>
                                form.setValues({
                                    warehouseId,
                                })
                            }
                            value={form.values.warehouseId}
                            isError={form.errors.warehouseId}
                        />
                    </FormSection>
                )}
                {shipment.status === ShipmentStatus.Booked && !isShipmentFromUserToUser(shipment) && (
                    <FormSection label='Schedule shipment' flex={1} marginBottom={20}>
                        <FormContainer>
                            <ShipmentScheduleForm form={form} />
                        </FormContainer>
                    </FormSection>
                )}

                <FormSection label='Number of boxes'>
                    <QuantitySelector
                        maxValue={50}
                        onChange={numberOfBoxes =>
                            form.setValues({
                                numberOfBoxes,
                            })
                        }
                        value={form.values.numberOfBoxes}
                    />
                </FormSection>

                {shipment.status === ShipmentStatus.Backorder && (
                    <FormSection label='Back order details'>
                        <FormDateRangeInput
                            label='Items estimated back order until'
                            api={form.fields.estimatedAvailability}
                            nullable
                        />
                    </FormSection>
                )}
                <FormSection label='Courier details'>
                    <ShipmentCourierForm form={form as UseForm<ShipmentCourierPayload>} />
                </FormSection>

                <FormSection label='Delivery Dates'>
                    <FormDateInput label='Ship By' api={form.fields.latestWithCourierOn} nullable />
                </FormSection>

                {(shipment.status === ShipmentStatus.Booked ||
                    shipment.status === ShipmentStatus.WithCourier) &&
                    (isShipmentFromWarehouseToUser(shipment) || isShipmentFromSupplierToUser(shipment)) && (
                        <FormSection label='Delivery details'>
                            <FormDateRangeInput
                                label='Estimated delivery date'
                                api={form.fields.estimateOn}
                                allowedRangeTypes={[DateRangeType.FromDate, DateRangeType.FromToDate]}
                                nullable
                            />
                        </FormSection>
                    )}

                {shipment.status === ShipmentStatus.Booked && isShipmentFromUserToWarehouse(shipment) && (
                    <FormSection label='Collection details'>
                        <FormDateRangeInput
                            label='Estimated collection date'
                            api={form.fields.estimateOn}
                            nullable
                        />
                    </FormSection>
                )}

                {shipment.status === ShipmentStatus.Booked && isShipmentFromUserToUser(shipment) && (
                    <FormSection label='Transfer details'>
                        <FormDateInput
                            label='Estimated transfer collection date'
                            api={form.fields.scheduledOn}
                            nullable
                        />
                        <FormDateRangeInput
                            label='Estimated transfer delivery date'
                            api={form.fields.estimateOn}
                            nullable
                        />
                    </FormSection>
                )}

                {(isShipmentFromUser(shipment) || isShipmentFromOrganizationWarehouse(shipment)) &&
                    fromAddress && (
                        <AddressField countryEditable={false} label='From address' api={fromAddress} />
                    )}
                {isShipmentToUser(shipment) && toAddress && (
                    <AddressField countryEditable={false} label='To address' api={toAddress} />
                )}

                {shipment.status === ShipmentStatus.WithCourier && isShipmentFromUserToUser(shipment) && (
                    <FormSection label='Transfer details'>
                        <FormDateRangeInput
                            label='Estimated transfer delivery date'
                            api={form.fields.estimateOn}
                            nullable
                        />
                    </FormSection>
                )}
                <Switch
                    label='Send update notification(s)'
                    checked={form.values.sendNotification}
                    onChange={sendNotification =>
                        form.setValues({
                            sendNotification,
                        })
                    }
                />
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton isLoading={isLoading} onClick={form.submit} label='Update details' />
            </SlideoutFooter>
        </Slideout>
    );
};
