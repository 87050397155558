import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
    assignmentsCacheKey,
    assignmentService,
    EnableStoreAndReuseCollectionPayload,
    getCountryBillingEntity,
} from '@hofy/api-admin';
import {
    CustomerOwnedStoreAndReuseLocation,
    rentalStoreAndReuseActivationFeeFromOption,
    RentalStoreAndReuseLocation,
} from '@hofy/api-shared';
import { Country, Currency, Price, UUID, zeroPrice } from '@hofy/global';
import { errorMap } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import { storeAndReuseLocationDefaultValue } from '../contracts/StoreAndReuseLocationDefaultValue';
import { useGetRentalStoreAndReuseFee } from '../contracts/useGetRentalStoreAndReuseFee';
import { useOrganizationDetailsQuery } from '../organizations/useOrganizationDetailsQuery';

export interface EnableStoreAndReuseCollectionFormData {
    rentalStoreAndReuse: {
        activationFee: Price | null;
        collectToLocation: RentalStoreAndReuseLocation | null;
    } | null;
    customerOwnedStoreAndReuse: {
        activationFee: Price | null;
        storageMonthlyFee: Price | null;
        collectToLocation: CustomerOwnedStoreAndReuseLocation | null;
        gracePeriodInMonths: number | null;
    } | null;
}

export interface EnableStoreAndReuseCollectionFormErrorData {
    rentalStoreAndReuse?: string;
    customerOwnedStoreAndReuse?: string;
}

interface UseEnableStoreAndReuseCollectionParams {
    organizationId: UUID;
    isActiveRental: boolean;
    assignmentId: UUID;
    contractId: UUID | null;
    country: Country;
    isStoreAndReuseCollectionToHubEnabled: boolean;
    isStoreAndReuseCollectionToLocalPartnerEnabled: boolean;
    onSuccess(): void;
}

export const useEnableStoreAndReuseCollection = ({
    organizationId,
    isActiveRental,
    assignmentId,
    contractId,
    country,
    isStoreAndReuseCollectionToHubEnabled,
    isStoreAndReuseCollectionToLocalPartnerEnabled,
    onSuccess,
}: UseEnableStoreAndReuseCollectionParams) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: EnableStoreAndReuseCollectionPayload) =>
            assignmentService.enableStoreAndReuseCollection(assignmentId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Store and reuse collection enabled',
            });
            onSuccess();
        },
    });

    const enableStoreAndReuseCollection = (data: EnableStoreAndReuseCollectionFormData) => {
        if (isActiveRental) {
            mutation.mutate({
                rentalStoreAndReuse: {
                    activationFee: data.rentalStoreAndReuse!.activationFee!,
                    collectToLocation: data.rentalStoreAndReuse!.collectToLocation!,
                },
                customerOwnedStoreAndReuse: null,
            });
        } else {
            mutation.mutate({
                rentalStoreAndReuse: null,
                customerOwnedStoreAndReuse: {
                    activationFee: data.customerOwnedStoreAndReuse!.activationFee!,
                    storageMonthlyFee: data.customerOwnedStoreAndReuse!.storageMonthlyFee!,
                    collectToLocation: data.customerOwnedStoreAndReuse!.collectToLocation!,
                    gracePeriodInMonths: 1,
                },
            });
        }
    };

    const { data: organizationDetails, isLoading: isLoadingOrg } =
        useOrganizationDetailsQuery(organizationId);
    const { data: fee } = useGetRentalStoreAndReuseFee(contractId);

    const form = useForm<
        EnableStoreAndReuseCollectionFormData,
        EnableStoreAndReuseCollectionFormData,
        EnableStoreAndReuseCollectionFormErrorData
    >({
        initial: {
            rentalStoreAndReuse: isActiveRental
                ? {
                      activationFee: zeroPrice(Currency.GBP),
                      collectToLocation: storeAndReuseLocationDefaultValue(
                          isStoreAndReuseCollectionToHubEnabled,
                          isStoreAndReuseCollectionToLocalPartnerEnabled,
                      ),
                  }
                : null,
            customerOwnedStoreAndReuse: !isActiveRental
                ? {
                      activationFee: zeroPrice(Currency.GBP),
                      storageMonthlyFee: zeroPrice(Currency.GBP),
                      collectToLocation: isStoreAndReuseCollectionToHubEnabled
                          ? CustomerOwnedStoreAndReuseLocation.HofyHub
                          : CustomerOwnedStoreAndReuseLocation.LocalHub,
                      gracePeriodInMonths: 1,
                  }
                : null,
        },
        onSubmit: formValues => {
            enableStoreAndReuseCollection(formValues);
        },
        validate: ({ rentalStoreAndReuse, customerOwnedStoreAndReuse }) => ({
            rentalStoreAndReuse: errorMap([
                isActiveRental && rentalStoreAndReuse === null,
                'Please select rental store and reuse options',
            ]),
            customerOwnedStoreAndReuse: errorMap([
                !isActiveRental && customerOwnedStoreAndReuse === null,
                'Please select customer store and reuse options',
            ]),
        }),
    });

    useEffect(() => {
        if (isActiveRental && fee) {
            const collectToLocation = form.values.rentalStoreAndReuse?.collectToLocation ?? null;
            form.setValues({
                rentalStoreAndReuse: {
                    activationFee:
                        rentalStoreAndReuseActivationFeeFromOption(fee, collectToLocation) ??
                        zeroPrice(Currency.GBP),
                    collectToLocation: form.values.rentalStoreAndReuse?.collectToLocation ?? null,
                },
            });
        }
        if (!isActiveRental && organizationDetails) {
            const defaultCurrency =
                getCountryBillingEntity(organizationDetails.billingEntities, country)?.currency ??
                Currency.GBP;
            form.setValues({
                customerOwnedStoreAndReuse: {
                    activationFee: zeroPrice(defaultCurrency),
                    storageMonthlyFee: zeroPrice(defaultCurrency),
                    collectToLocation: form.values.customerOwnedStoreAndReuse?.collectToLocation ?? null,
                    gracePeriodInMonths: 1,
                },
            });
        }
    }, [fee, form.values.rentalStoreAndReuse?.collectToLocation, organizationDetails]);

    return {
        form,
        isLoading: isLoadingOrg || mutation.isPending,
    };
};
