import { useUsersForEnrollQuery } from '@hofy/api-admin';
import { formatOptionalUserName } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

interface UserIdFilterDefinitionOptions {
    organizationId: UUID;
    organizationContractId: UUID;
}

export const useUserIdFilterDefinition = ({
    organizationId,
    organizationContractId,
}: UserIdFilterDefinitionOptions) => {
    const { users: allUsers } = useUsersForEnrollQuery(organizationId, organizationContractId);

    const userIdLabel = (id: UUID) => {
        const user = allUsers.find(user => user.id === id);
        return formatOptionalUserName(user);
    };

    const allUserIds = allUsers.map(user => user.id);

    return {
        toLabel: userIdLabel,
        allValues: allUserIds,
    };
};
