import React, { FC } from 'react';

import { IssueDetailsDto } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { BackButton, Overlay } from '@hofy/common';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import { AsyncButton, Box, FormSection, PageHeader, SvgIcon } from '@hofy/ui';

import { Footer } from '../../../components/design/layout/Footer';
import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { useUpdateIssue } from '../../../store/issues/useUpdateIssue';
import { ContractDetails } from '../../assignmentSlideout/components/ContractDetails';
import { AssignmentDetailsSection } from './AssignmentDetailsSection';
import { IssueDetailsSection } from './IssueDetailsSection';
import { ProductDetailsSection } from './ProductDetailsSection';
import { UserDetailsSection } from './UserDetailsSection';

interface IssueDetailsPageProps {
    issue: IssueDetailsDto;
}

export const IssueDetailsPage: FC<IssueDetailsPageProps> = ({ issue }) => {
    const { updateIssueIsLoading, form } = useUpdateIssue(issue);
    const { hasPermission } = usePermission();
    const hasPermissionToEdit = hasPermission(Permission.AdminIssuesUpdate);

    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <PageHeader title='Issue details' />
            <Box flex='auto' overflow='auto' paddingBottom={40}>
                <Box paddingLeft={40} paddingTop={30} gap={40} row alignItems='flex-start'>
                    <Box flex={1} column gap={40}>
                        <IssueDetailsSection issue={issue} form={form} editable={hasPermissionToEdit} />
                        {issue.product && (
                            <ProductDetailsSection product={issue.product} variant={issue.variant} />
                        )}
                    </Box>
                    <Box flex={1}>
                        {issue.assignment && <AssignmentDetailsSection assignment={issue.assignment} />}
                        {issue.contract && (
                            <FormSection label='Contract details'>
                                <ContractDetails contract={issue.contract} />
                            </FormSection>
                        )}
                        <UserDetailsSection user={issue.user} />
                    </Box>
                </Box>
            </Box>
            <Footer>
                <BackButton
                    defaultNavigation={AdminNavLink.Issues}
                    label='Back'
                    leftIcon={SvgIcon.ChevronLeft}
                />
                <AsyncButton
                    isLoading={updateIssueIsLoading}
                    label='Save'
                    onClick={form.submit}
                    disabled={!hasPermissionToEdit}
                />
            </Footer>
        </Overlay>
    );
};
