import React, { FC } from 'react';

import { useBYODOrderQuery } from '@hofy/api-admin';
import { ComponentLoader, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { ErrorStatePlaceholder } from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { BYODOrderDetailsContent } from './BYODOrderDetailsContent';

interface BYODOrderDetailsSlideoutProps {
    id: UUID;
    onClose(): void;
}

export const BYODOrderDetailsSlideout: FC<BYODOrderDetailsSlideoutProps> = ({ id: byodOrderId, onClose }) => {
    const { data: byodOrder, isLoading, isError } = useBYODOrderQuery(byodOrderId);

    if (isLoading) {
        return <ComponentLoader />;
    }

    if (!byodOrder || isError) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <Slideout width={1000} onClose={onClose} slideoutId='byod-order'>
            <SlideoutHeader title={`BYOD order ${byodOrder.publicId}`} />
            <SlideoutContent>
                <BYODOrderDetailsContent byodOrder={byodOrder} />
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton label='Close' onClick={onClose} />
            </SlideoutFooter>
        </Slideout>
    );
};
