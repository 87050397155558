import React, { FC } from 'react';

import { OrganizationDetailsDto } from '@hofy/api-admin';
import { Currency, formatPriceWithCurrency } from '@hofy/global';
import {
    AsyncButton,
    Box,
    FormContainer,
    FormNumberInput,
    FormPriceInput,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { useUpdateOrganizationStoreAndReuse } from '../../../store/organizations/useUpdateOrganizationStoreAndReuse';

interface StoreAndReuseUpdateModalProps {
    organization: OrganizationDetailsDto;
    onClose(): void;
}

export const StoreAndReuseUpdateModal: FC<StoreAndReuseUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useUpdateOrganizationStoreAndReuse(organization, onClose);
    const { tr } = useAdminI18n();

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title={'Store for reuse'} subtitle={tr('organization-page.byod-update.subtitle')} />
            <ModalContent>
                <FormContainer>
                    <FormPriceInput
                        label={'Rental activation fee (local)'}
                        clearable
                        api={form.fields.rentalLocalActivationFee}
                        defaultCurrency={Currency.USD}
                        helperText={`Default ${formatPriceWithCurrency(organization.defaultPricing.byodActivationFee)}`}
                        nullable
                    />
                    <FormPriceInput
                        label={'Rental activation fee (zone1)'}
                        clearable
                        api={form.fields.rentalZone1ActivationFee}
                        defaultCurrency={Currency.USD}
                        helperText={`Default ${formatPriceWithCurrency(organization.defaultPricing.storeAndReuseRentalZone1ActivationFee)}`}
                        nullable
                    />
                    <Box gap={10} />
                    <FormPriceInput
                        label={'Customer owned activation fee (local)'}
                        clearable
                        api={form.fields.customerOwnedLocalActivationFee}
                        defaultCurrency={Currency.USD}
                        helperText={`Default ${formatPriceWithCurrency(organization.defaultPricing.storeAndReuseCustomerOwnedLocalActivationFee)}`}
                        nullable
                    />
                    <FormPriceInput
                        label={'Customer owned activation fee (to hub)'}
                        clearable
                        api={form.fields.customerOwnedHubActivationFee}
                        defaultCurrency={Currency.USD}
                        helperText={`Default ${formatPriceWithCurrency(organization.defaultPricing.storeAndReuseCustomerOwnedHubActivationFee)}`}
                        nullable
                    />
                    <FormPriceInput
                        label={'Customer owned storage fee'}
                        clearable
                        api={form.fields.customerOwnedStorageFee}
                        defaultCurrency={Currency.USD}
                        helperText={`Default ${formatPriceWithCurrency(organization.defaultPricing.storeAndReuseCustomerOwnedStorageFee)}`}
                        nullable
                    />
                    <FormNumberInput
                        label={'Customer owned storage grace period'}
                        clearable
                        api={form.fields.gracePeriodInMonths}
                        helperText={`Default ${organization.defaultPricing.storeAndReuseGracePeriodInMonths} months`}
                        nullable
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label={tr('organization-page.byod-update.modal.cancel')} onClick={onClose} />
                <AsyncButton
                    label={tr('organization-page.byod-update.modal.confirm')}
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
