import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    EnrollUsersPayload,
    organizationContractCacheKey,
    organizationContractService,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { useEnrollUsersForm } from './useEnrollUsersForm';

export const useEnrollUsers = (onSuccess: () => void, organizationId: UUID, organizationContractId: UUID) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: EnrollUsersPayload) => {
            return organizationContractService.enrollUsers(organizationId, organizationContractId, payload);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationContractCacheKey] });
            showToast({
                type: 'positive',
                message: 'Users enrolled successfully',
            });
            onSuccess();
        },
    });

    return useEnrollUsersForm(mutation);
};
