import { DateRangeStrings, DateRangeType } from '@hofy/global';
import { dateRangeToRangeName, dateRangeToRangeType, formatDateRange } from '@hofy/helpers';
import { useTrDateRangeName, useTrDateRangeType } from '@hofy/i18n';

/**
 * Formats a date range into a string.
 * If the range is a known range name, it will be translated.
 * If no range name is found, the range will be formatted as a date range.
 */
export const useFormatDateRange = () => {
    const trRangeName = useTrDateRangeName();
    const trRangeType = useTrDateRangeType();

    return (dateRange: DateRangeStrings): string => {
        const rangeName = dateRangeToRangeName(dateRange);

        if (rangeName) {
            return trRangeName(rangeName); // e.g.: This month
        }

        const rangeType = dateRangeToRangeType(dateRange);
        const formatted = formatDateRange(dateRange);

        if (rangeType === DateRangeType.FromToDate) {
            return formatted; // e.g.: 01 Jan 2021 - 01 Jan 2022
        }

        const fromOrTo = trRangeType(rangeType);

        return `${fromOrTo} ${formatted}`; // e.g.: After 01 Jan 2021
    };
};
