import React, { FC, memo } from 'react';

import { SeatAssignmentDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { BaseTable, Placeholder, SvgIllustration, TextCell } from '@hofy/ui';

import { SeatAssignmentStatusChip } from '../../../../components/domain/organizationContracts/SeatAssignmentStatusChip';
import { useDeactivateUsersOrganizationContract } from '../../../../store/organizationContracts/useDeactivateUsersForOrganizationContract';
import { UserDetailsLink } from '../../../peoplePage/users/UserLink';
import { SeatAssignmentActions } from './SeatAssignmentActions';

interface SeatAssignmentTableComponentProps {
    organizationId: UUID;
    organizationContractId: UUID;
    seatAssignments: SeatAssignmentDto[];
}

const SeatAssignmentTableComponent: FC<SeatAssignmentTableComponentProps> = ({
    organizationId,
    organizationContractId,
    seatAssignments,
}) => {
    const { deactivateUser } = useDeactivateUsersOrganizationContract(organizationId, organizationContractId);

    return (
        <BaseTable
            data={seatAssignments}
            toKey={v => v.id}
            hoverable
            emptyContent={<Placeholder illustration={SvgIllustration.List} title='No seat assignments' />}
            columns={[
                {
                    id: 'user',
                    header: 'User',
                    renderer: (seat: SeatAssignmentDto) => (
                        <TextCell>
                            <UserDetailsLink user={seat.user} />
                        </TextCell>
                    ),
                },
                {
                    id: 'status',
                    header: 'Status',
                    renderer: (seat: SeatAssignmentDto) => <SeatAssignmentStatusChip status={seat.status} />,
                },
                {
                    id: 'activeOn',
                    header: 'Active on',
                    renderer: (seat: SeatAssignmentDto) => formatDate(seat.activeOn),
                },
                {
                    id: 'deactivatedOn',
                    header: 'Deactivated on',
                    renderer: (seat: SeatAssignmentDto) => formatDate(seat.deactivatedOn),
                },
                {
                    id: 'declinedAt',
                    header: 'Declined at',
                    renderer: (seat: SeatAssignmentDto) => formatDate(seat.declinedAt),
                },
                {
                    id: 'actions',
                    header: 'Actions',
                    flexGrow: 0,
                    renderer: (seat: SeatAssignmentDto) => (
                        <SeatAssignmentActions seat={seat} handleDeactivateUser={deactivateUser} />
                    ),
                },
            ]}
        />
    );
};

export const SeatAssignmentTable = memo(SeatAssignmentTableComponent);
