import React from 'react';

import { Box, ExpandHeight, FilterChip, Paragraph3 } from '@hofy/ui';

export interface SelectedItemsChipsBoxProps<T> {
    title: string;
    items: T[];
    toLabel: (id: T) => string;
    remove: (id: T) => void;
}
export const SelectedItemsChipsBox = <T,>({
    title,
    items,
    toLabel,
    remove,
}: SelectedItemsChipsBoxProps<T>) => {
    return (
        <ExpandHeight>
            <Paragraph3 paddingRight={8}>
                {title} ({items.length})
            </Paragraph3>
            {items.length > 0 && (
                <Box paddingVertical={4} rounded={8} border borderStyle='dashed'>
                    {items.map(item => (
                        <Box key={`box-${item}`} inline row gap={6} paddingHorizontal={8} paddingVertical={4}>
                            <FilterChip
                                key={`filter-chip-${item}`}
                                label={toLabel(item)}
                                onClear={() => {
                                    remove(item);
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </ExpandHeight>
    );
};
