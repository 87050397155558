import { useMutation, useQueryClient } from '@tanstack/react-query';

import { OrganizationDetailsDto, organizationService } from '@hofy/api-admin';
import { useForm, useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';
import { UpdateOrganizationStoreAndReuseFormData } from './types/UpdateOrganizationStoreAndReuseFormData';

export const useUpdateOrganizationStoreAndReuse = (
    organization: OrganizationDetailsDto,
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationStoreAndReuseFormData) =>
            organizationService.updateOrganizationStoreAndReuse(organization.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            showToast({
                message: `Organization ${organization.name} store and reuse successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const form = useForm<UpdateOrganizationStoreAndReuseFormData>({
        initial: {
            gracePeriodInMonths: organization.pricing.storeAndReuseGracePeriodInMonths,
            rentalZone1ActivationFee: organization.pricing.storeAndReuseRentalZone1ActivationFee,
            rentalLocalActivationFee: organization.pricing.storeAndReuseRentalLocalActivationFee,
            customerOwnedHubActivationFee: organization.pricing.storeAndReuseCustomerOwnedHubActivationFee,
            customerOwnedLocalActivationFee:
                organization.pricing.storeAndReuseCustomerOwnedLocalActivationFee,
            customerOwnedStorageFee: organization.pricing.storeAndReuseCustomerOwnedStorageFee,
        },
        onSubmit: mutation.mutate,
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
